import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslate } from "ra-core";
import {
  Button as ButtonReactAdmin,
  FormTab,
  TopToolbar,
  useNotify,
} from "react-admin";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Box, ThemeProvider } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import ExitToApp from "@material-ui/icons/ExitToApp";
import DevicesIcon from "@material-ui/icons/Devices";
import { Button as ButtonMui } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelIcon from "@material-ui/icons/Cancel";
import muiTheme from "../../../helper/muiTheme";
import botProvider from "../../../synapse/botProvider";
import { Pagination } from "../../common/Pagination";
import { logger } from "../../../utils/logger";

function CustomToolbar() {
  return (
    <Box
      sx={{
        display: "flex",
        fontWeight: "bold",
        p: 2,
        justifyContent: "space-between",
      }}
    >
      <GridToolbarQuickFilter variant="outlined" />
    </Box>
  );
}

const BotDevices = ({
  id,
  devices,
  fetching,
  deleteDevices,
  ...anotherProps
}) => {
  const notify = useNotify();
  const translate = useTranslate();

  const [isLoading, setIsLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  // Target device used for Dialog
  const [device, setDevice] = useState({});

  const handleDialogClose = () => setOpenConfirm(false);

  const handleConfirm = (botId, deviceId) => {
    setIsLoading(true);
    botProvider
      .deleteDevices(botId, deviceId)
      .then(() => {
        notify("resources.bots.actions.delete_device_success", {
          type: "success",
        });
        deleteDevices([deviceId]);
      })
      .catch(error => {
        logger.error(error);
        notify("resources.common.failed_to_operate_please_refresh", {
          type: "error",
        });
      })
      .finally(() => {
        setOpenConfirm(false);
        setIsLoading(false);
      });
  };

  const handleDeleteDevice = e => {
    e.preventDefault();
    e.stopPropagation();
    setOpenConfirm(true);
  };
  const columns = [
    {
      field: "device_id",
      headerName: "Device-ID",
      width: 500,
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "display_name",
      headerName: "Device Name",
      width: 400,
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "last_seen_ip",
      headerName: "Last seen IP",
      width: 150,
      sortable: true,
      disableColumnMenu: true,
      flex: 0,
    },
    {
      field: "last_seen_ts",
      headerName: "Last seen TS",
      width: 250,
      sortable: true,
      disableColumnMenu: true,
      flex: 0,
      valueFormatter: ({ value }) => {
        const ts = new Date(value);
        return ts.toLocaleDateString() + " " + ts.toLocaleTimeString();
      },
    },
    {
      field: "last_seen_user_agent",
      headerName: "Last seen User Agent",
      width: 250,
      sortable: true,
      disableColumnMenu: true,
      flex: 0,
    },
    {
      field: "actions",
      headerName: "",
      width: 300,
      sortable: false,
      disableColumnMenu: true,
      flex: 0,
      renderCell: params => {
        const record = { ...params.row };
        return (
          <TopToolbar
            style={{
              margin: "0 0 15px 10px",
            }}
          >
            {
              !record.display_name?.startsWith("Bot") &&
              <ButtonMui
                style={{
                  textTransform: "capitalize",
                  backgroundColor: muiTheme.palette.error.main,
                }}
                variant="contained"
                color="primary"
                label="resources.bots.actions.delete_device"
                onClick={e => {
                  setDevice(record);
                  handleDeleteDevice(e);
                }}
              >
                <ExitToApp style={{ marginRight: "10px" }} />
                {translate("resources.bots.actions.delete")}
              </ButtonMui>
            }
          </TopToolbar>
        );
      },
    },
  ];

  return (
    <FormTab
      {...anotherProps}
      label="resources.bots.tabs.devices"
      icon={<DevicesIcon />}
    >
      {fetching ? (
        <div
          style={{
            margin: "75px auto 100px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={50} thickness={2} />
        </div>
      ) : (
        <ThemeProvider theme={muiTheme}>
          <Box
            marginBottom="10px"
            sx={{
              maxWidth: "calc(100vw - 295px)",
              height: "70vh",
            }}
          >
            <DataGrid
              pagination
              style={{ borderWidth: 0 }}
              rows={devices}
              columns={columns}
              getRowId={row => row?.device_id}
              initialState={{
                pagination: { paginationModel: { pageSize: 50 } },
                sorting: {
                  sortModel: [{ field: "last_seen_ts", sort: "desc" }],
                },
              }}
              pageSizeOptions={[10, 25, 50]}
              slots={{
                pagination: Pagination,
                toolbar: CustomToolbar,
              }}
            />
          </Box>
          <Dialog aria-labelledby="reset-dialog-title" open={openConfirm}>
            <DialogTitle id="reset-dialog-title">
              {translate("resources.bots.actions.delete_device_confirm_title", {
                device: device.display_name ?? device.device_id,
              })}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="reset-dialog-description">
                {translate(
                  "resources.bots.actions.delete_device_confirm_content",
                  {
                    device: device.display_name ?? device.device_id,
                  }
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ButtonReactAdmin
                onClick={handleDialogClose}
                color="inherit"
                label="Cancel"
              >
                <CancelIcon />
              </ButtonReactAdmin>
              {isLoading ? (
                <CircularProgress
                  style={{ height: "20px", width: "20px" }}
                  color="primary"
                />
              ) : (
                <ButtonReactAdmin
                  label="Confirm"
                  onClick={() => {
                    handleConfirm(id, device.device_id);
                  }}
                  color="primary"
                >
                  <CheckIcon />
                </ButtonReactAdmin>
              )}
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      )}
    </FormTab>
  );
};
BotDevices.propTypes = {
  deleteDevices: PropTypes.func.isRequired,
  fetchRoom: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  devices: PropTypes.array,
  id: PropTypes.string,
};
export default BotDevices;
