export const EBotHelp = {
  M_BROADCAST_MESSAGE: "M_BROADCAST_MESSAGE",
  M_SEND_MESSAGE: "M_SEND_MESSAGE",
  M_DIRECT_MESSAGE: "M_DIRECT_MESSAGE",
  M_REQUEST_STATUS: "M_REQUEST_STATUS",
};

export const BotType = {
  BOT: "bot",
  APPSERVICE: "appservice",
};
